import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="Header">
        <h1 className="Title">Reese Technology Services</h1>
        <nav className="Nav">
          <a href="#home">Home</a>
          <a href="#services">Services</a>
          <a href="#about">About Us</a>
          <a href="#rates">Rates</a>
          <a href="#contact">Contact</a>
        </nav>
      </header>

      <section id="home" className="Section">
        <h2>Home</h2>
        <p>Reese Technology Services is your source for fast, friendly, and transparent on-site tech support in Reno, NV.</p>
      </section>

      <section id="services" className="Section">
        <h2>Services</h2>
        <p>We provide a range of services for residences and businesses, such as:</p>
        <ul>
          <p>Computer Hardware and Software Repair</p>
          <p>Computer, Phone, and Tablet Setup</p>
          <p>Windows and MacOS Health Checkups</p>
          <p>Windows and MacOS Backup and Reinstall</p>
          <p>Virus Removal</p>
          <p>Network Setup</p>
          <p>One-on-One Tech Tutoring</p>
          <p>and many other services...</p>
        </ul>
      </section>

      <section id="about" className="Section">
        <h2>About Us</h2>
        <p>Bryson Reese, a computer enthusiast with a Bachelor's in Computer Science and Engineering from the University of Nevada, Reno, 
          founded Reese Technology Services to offer friendly and honest tech support. 
          <br />
          <br />
          Trusted by family and friends, Bryson is committed to helping you 
          with everything from troubleshooting to setup, ensuring your satisfaction and empowering you with the knowledge to make the most of your technology.
        </p>
      </section>

      <section id="rates" className="Section">
        <h2>Rates</h2>
        <p>On-Site Rate (computer setup, tutoring, etc): $120/hr, billed in 15 minute increments with minimum 1/2 hour charge</p>
        <p>Flat Rate Services (hardware repair, health checkup, backup and reinstall): contact for quote</p>
      </section>

      <section id="contact" className="Section">
        <h2>Contact</h2>
        <p>If you have any questions or would like a free quote, please do not hesitate to reach out via call or text at (530) 306-0471 or via email at bryson@brysonreese.com.</p>
      </section>
    </div>
  );
}

export default App;
